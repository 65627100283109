// src/api/auth.js

import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL ? `${process.env.REACT_APP_BACKEND_URL}/api/customers` : 'https://zasheroku-b6ad972d04c9.herokuapp.com/api/customers';

export const getCustomerData = async (phone) => {
  try {
    const response = await axios.get(`${API_URL}/by-phone/${phone}` );
    return response.data;  // This will be the response from your backend (e.g., { token: '...'})
  } catch (error) {
    console.error('Error during fetching customer data:', error);
    throw error;
  }
};

export const deleteCustomer = async (phone) =>{
  try {
    const response = await axios.delete(`${API_URL}/${phone}` );
    return response.data;  // This will be the response from your backend (e.g., { token: '...'})
  } catch (error) {
    console.error('Error during deleting customer data:', error);
    throw error;
  }
}


export const getIdByPhone = async (phone) => {
  try {
    const response = await axios.get(`${API_URL}/get-id/${phone}` );
    return response.data;  // This will be the response from your backend (e.g., { token: '...'})
  } catch (error) {
    console.error('Error during fetching customer data:', error);
    throw error;
  }
};


export const getAllCustomerData = async () => {
  try {
    const response = await axios.get(`${API_URL}/` );
    return response.data;  // This will be the response from your backend (e.g., { token: '...'})
  } catch (error) {
    console.error('Error during fetching customer data:', error);
    throw error;
  }
};

export const addCustomer = async (formData) => {
  try {
    console.log(formData);
    const response = await axios.post(`${API_URL}`, formData);
      return response.data;  // This will be the response from your backend (e.g., { token: '...'})
  } catch (error) {
    console.error('Error during saving data:', error);
    throw error;
  }
};

export const updateCustomer = async (formData) => {
  try {
    const customer = await getIdByPhone(formData.oldPhone);
    console.log(customer);
    const response = await axios.post(`${API_URL}/updateCustomer/${customer.id}`, formData);
      return response.data;  // This will be the response from your backend (e.g., { token: '...'})
  } catch (error) {
    console.error('Error during saving data:', error);
    throw error;
  }
};

export const getRenewalsThisMonth = async() => {
  try {
    const response = await axios.get(`${API_URL}/renewals-this-month`);
      return response.data.length;  // This will be the response from your backend (e.g., { token: '...'})
  } catch (error) {
    console.error('Error during fetching renewals this month:', error);
    throw error;
  }
}


