import React from "react";
import { Image } from "./image";

export const Products = (props) => {
  return (
    <div id="products" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Products</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-sm-6 col-md-3">
                  {" "}
                  <div className="image-container">
                    <img src={d.img} alt={d.title} title={d.title} />
                  </div>          

                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
