import React, { useState, useMemo, useEffect } from 'react';
import { getAllCustomerData, getRenewalsThisMonth } from '../api/customerData';
import { getFormattedDate } from '../api/utils';
import { servicesPerWeek } from '../api/serviceData';
import { Modal } from './Modal';
import { getAllMachines } from '../api/machine';
import { getParts } from '../api/parts';
import ReusableTable from './reusableTable';

// Columns definitions
export const Admin = ({ data }) => {
  const { machineSubCategory, parts, cities } = data;
  const [customerFilterInput, setCustomerFilterInput] = useState('');
  const [partFilterInput, setPartFilterInput] = useState('');
  const [machineFilterInput, setMachineFilterInput] = useState('');
  const [customerData, setCustomerData] = useState([]);
  const [partsData, setPartsData] = useState([]);
  const [machineData, setMachineData] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [noOfCustomers, setNoOfCustomers] = useState(0);
  const [servicesThisWeek, setServicesThisWeek] = useState(0);
  const [renewalsThisMonth, setRenewalsThisMonth] = useState(0);
  const customerColumns = useMemo(() => [
    { Header: 'Name', accessor: 'name' },
    { Header: 'Phone', accessor: 'phone' },
    { Header: 'Address', accessor: 'address' },
    { Header: 'City', accessor: 'city' },
    { Header: 'Machine Type', accessor: 'machine_type' },
    { Header: 'Contract Start Date', accessor: 'start_date', Cell: ({ value }) => getFormattedDate(value)},
    { Header: 'Contract End Date', accessor: 'end_date', Cell: ({ value }) => getFormattedDate(value)},
  ], []);
  
  const partColumns = useMemo(() => [
    { Header: 'Part Name', accessor: 'partName' },
    { Header: 'Machine Type', accessor: 'machineType' },
    { Header: 'Machine Category', accessor: 'machineSubCategory' },
    { Header: 'Part Description', accessor: 'partDescription' },
    { Header: 'Part Price', accessor: 'partPrice' },
  ], []);
  
  const machineColumns = useMemo(() => [
    { Header: 'Machine Type', accessor: 'machineType' },
    { Header: 'Machine Name', accessor: 'machineName' },
    { Header: 'Machine Category', accessor: 'machineSubCategory' },
    { Header: 'Machine Description', accessor: 'machineDescription' },
    { Header: 'Machine Price', accessor: 'machinePrice' },

  ], []);
  


  const openModal = (type) => {
    setModalType(type);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalType('');
  };

  // Fetch data
  useEffect(() => {
    const fetchData = async () => {
      const customers = await getAllCustomerData();
      setCustomerData(customers);
      setNoOfCustomers(customers.length);
      const servicesCount = await servicesPerWeek();
      setServicesThisWeek(servicesCount);
      const renewals = await getRenewalsThisMonth();
      setRenewalsThisMonth(renewals);
      const partsList = await getParts();
      setPartsData(partsList);
      const machines = await getAllMachines();
      setMachineData(machines);
    };

    fetchData();
    const intervalId = setInterval(fetchData, 60000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div id="dashboard">
      <div className="admin">
        <div className="container">
          <div className="row">
            <div className="col-md-12 intro-text">
              <div className="box">
                <span>No of Customers</span>
                {noOfCustomers > 0 && (
                  <div className="badge">{noOfCustomers}</div>
                )}
              </div>
              <div className="box">
                <span>Services This Week</span>
                <div className="badge">{servicesThisWeek}</div>
              </div>
              <div className="box">
                <span>Renewals This Month</span>
                <div className="badge">{renewalsThisMonth}</div>
              </div>
              <div className="adminBtn">
                <div className="button-group">
                  <button
                    type="button"
                    className="btn btn-custom btn-lg"
                    onClick={() => openModal('customer')}
                  >
                    Add New Customer
                  </button>
                  <button
                    type="button"
                    className="btn btn-custom btn-lg"
                    onClick={() => openModal('part')}
                  >
                    Add New Part
                  </button>
                  <button
                    type="button"
                    className="btn btn-custom btn-lg"
                    onClick={() => openModal('machine')}
                  >
                    Add New Machine
                  </button>
                </div>
              </div>
              <ReusableTable
                columns={customerColumns}
                data={customerData}
                filterInput={customerFilterInput}
                setFilterInput={setCustomerFilterInput}
                subCategory={machineSubCategory}
                tableType="Customer"
                cities={cities}
                parts={parts}
              />
              <ReusableTable
                columns={partColumns}
                data={partsData}
                filterInput={partFilterInput}
                setFilterInput={setPartFilterInput}
                subCategory={machineSubCategory}
                tableType="Part"
                cities={cities}
                parts={parts}
              />
              <ReusableTable
                columns={machineColumns}
                data={machineData}
                filterInput={machineFilterInput}
                setFilterInput={setMachineFilterInput}
                subCategory={machineSubCategory}
                tableType="Machine"
                cities={cities}
                parts={parts}
              />
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          type={modalType} // Pass the correct type
          subCategory={machineSubCategory}
          parts={parts}
          cities={cities}
        />
      )}
    </div>
  );
};
