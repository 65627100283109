import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { addCustomer, updateCustomer } from '../api/customerData';
import { addPart, updatePart } from '../api/parts';  // Adjust the path as needed
import { addMachine, getMachines, updateMachine } from '../api/machine'; // Adjust the path as needed
import { formatDateString } from '../api/utils';

export const Modal = ({ isOpen, onClose, type, subCategory, initialData, parts, cities }) => {
  const [formData, setFormData] = useState({});
  const [action, setAction] = useState('Add');
  const [machineOptions, setMachineOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [machineData, setMachineData] = useState([]);
  const [selectedMachineType, setSelectedMachineType] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [oldPhoneNumber, setOldPhoneNumber] = useState(''); // Store old phone number
  const [filteredParts, setFilteredParts] = useState([]); // State for filtered parts
  console.log(cities);
  useEffect(() => {
    if (isOpen) {
      const fetchMachines = async () => {
        try {
          const response = await getMachines();
          setMachineData(response);

          const typeOptions = response.map(machine => ({
            value: machine.machineType,
            label: machine.machineType
          }));
          setMachineOptions(typeOptions);
        } catch (error) {
          console.error('Failed to fetch machines:', error);
        }
      };

      fetchMachines();
      initializeFormData();
    } else {
      resetForm();
    }
  }, [isOpen, type]);

  useEffect(() => {
    if (initialData) {
      setAction('Edit');
      setOldPhoneNumber(initialData.phone); // Store the old phone number
      const machineType  = initialData.machine_type;
      const partPrice = initialData.partPrice ? parseFloat(initialData.partPrice.replace(/[^0-9.]/g, '')) || 0 : ''; // Convert to number
      const machinePrice = initialData.machinePrice ? parseFloat(initialData.machinePrice.replace(/[^0-9.]/g, '')) || 0: ''; // Convert to number
      if (machineType) {
        const lastIndex = machineType.lastIndexOf('-');
        const mtype = machineType.substring(0, lastIndex).trim();
        const subcategory = machineType.substring(lastIndex + 1).trim();
        setFormData({
          ...initialData,
          machineType: mtype,
          machineSubCategory: subcategory, // Ensure this matches your structure
          partPrice, 
          machinePrice
        });
        setSelectedMachineType(mtype);
      } else {
        setFormData({
          ...initialData,
          partPrice, 
          machinePrice
        });
      }
    } else {
      setFormData({});
    }
  }, [initialData]);

  console.log(formData);
  
  useEffect(() => {
    if (selectedMachineType) {
      const selectedMachine = machineData.find(machine => machine.machineType === selectedMachineType);
      const subCategories = selectedMachine ? selectedMachine.subCategories : [];
      setSubCategoryOptions(subCategories.map(subCat => ({
        value: subCat,
        label: subCat
      })));

      const machineParts = parts[0][selectedMachineType]; // Access parts for the selected machine type
      if (machineParts) {
        const filtered = machineParts.map(part => ({
          value: part.value,
          label: part.label
        }));
        setFilteredParts(filtered);
      }
      // Set the selected subcategory if it exists in the options
      const existingSubcategory = subCategories.find(subCat => subCat === formData.machineSubCategory);
      if (existingSubcategory) {
        setFormData(prevData => ({ ...prevData, machineSubCategory: existingSubcategory }));
      } else {
        setFormData(prevData => ({ ...prevData, machineSubCategory: '' })); // Reset if not found
      }
    } else {
      setSubCategoryOptions([]);
      setFilteredParts([]);
    }
  }, [selectedMachineType, machineData]);  

  
  const initializeFormData = () => {
    switch (type.toLowerCase()) {
      case 'customer':
        setFormData({
          name: '',
          phone: '',
          address: '',
          city: '',
          pin:'',
          email: '',
          start_date: '',
          end_date: '',
          machineType: '',
          machineSubCategory: ''
        });
        break;
      case 'part':
        setFormData({
          partName: '',
          machineType: '',
          machineSubCategory: '',
          partDescription: '',
          partCost: ''
        });
        break;
      case 'machine':
        setFormData({
          machineType: '',
          machineSubCategory: '',
          machineName: '',
          machineDescription: '',
          machinePrice: ''
        });
        break;
      default:
        setFormData({});
        break;
    }
  };


  const resetForm = () => {
    setFormData({});
    setSelectedMachineType(null);
    setSubCategoryOptions([]);
  };

  useEffect(() => {
    if (successMessage || errorMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
        setErrorMessage('');
      }, 60000);

      return () => clearTimeout(timer);
    }
  }, [successMessage, errorMessage]);
  
  const handleDropDownChange = (field) => (option) => {
    setFormData(prevData => {
      const newFormData = { ...prevData, [field]: option ? option.value : '' };
      
      if (field === 'machineType') {
        setSelectedMachineType(option ? option.value : '');
        newFormData['machineSubCategory'] = '';
      }
      return newFormData;
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => {
      const updatedData = { ...prevData, [name]: value };
  
      // Check if the changed field is 'start_date'
      if (name === 'start_date') {
        // Calculate the end date as one year after the start date
        const startDate = new Date(value);
        const endDate = new Date(startDate.setFullYear(startDate.getFullYear() + 1));
        updatedData.end_date = formatDateString(endDate);
      }
  
      return updatedData;
    });
    };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSuccessMessage('');
      setErrorMessage('');
      if (action.toLowerCase() === 'edit') {
        switch (type.toLowerCase()) {
          case 'customer':
            await updateCustomer({ ...formData, oldPhone: oldPhoneNumber }); 
            break;
          case 'part':
            await updatePart(formData); // Update part data
            break;
          case 'machine':
            await updateMachine(formData); // Update machine data
            break;
          default:
            throw new Error('Unknown type');
        }
      } else { // For 'add' action
        switch (type.toLowerCase()) {
          case 'customer':
            await addCustomer(formData); // Add new customer
            break;
          case 'part':
            await addPart(formData); // Add new part
            break;
          case 'machine':
            await addMachine(formData); // Add new machine
            break;
          default:
            throw new Error('Unknown type');
        }
      }

      setSuccessMessage(`Successfully ${action.toLowerCase()}ed ${type.charAt(0).toUpperCase() + type.slice(1)}!`);
      setFormData({});
      setSelectedMachineType(null);
      setSubCategoryOptions([]);
      setTimeout(onClose, 2000);
    } catch (error) {
      console.error('Error adding data:', error);
      setErrorMessage(error.response ? error.response.data.message : 'Failed to add machine');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>X</button>
        <h2>{`${action} ${type.charAt(0).toUpperCase() + type.slice(1)} Details`}</h2>
        <p>Please add all the required fields below</p>
        <form onSubmit={handleSubmit} validate className="serviceComplete">
          {type.toLowerCase() === 'customer' && (
            <>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Name</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      placeholder="Customer Name"
                      value={formData.name || ''}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Phone Number</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    className="form-control"
                    placeholder="Customer Phone Number"
                    value={formData.phone || ''}
                    onChange={handleChange}
                    required
                  />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Email (Optional)</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Customer Email"
                    value={formData.email || ''}
                    onChange={handleChange}
                  />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Address</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <input
                    type="text"
                    name="address"
                    className="form-control"
                    placeholder="Customer Address"
                    value={formData.address || ''}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>City</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <Select
                      options={cities}
                      value={cities.find(option => option.value === formData.city) || null}
                      onChange={handleDropDownChange('city')}
                      classNamePrefix="react-select"
                    />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Pin Code</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <input
                    type="number"
                    name="pin"
                    className="form-control"
                    placeholder="Pin Code"
                    value={formData.pin || ''}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Machine Type</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <Select
                      options={machineOptions}
                      value={machineOptions.find(option => option.value === formData.machineType) || null}
                      onChange={handleDropDownChange('machineType')}
                      classNamePrefix="react-select"
                    />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Machine SubCategory</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <Select
                      options={subCategoryOptions}
                      value={subCategoryOptions.find(option => option.value === formData.machineSubCategory) || null}
                      onChange={handleDropDownChange('machineSubCategory')}
                      classNamePrefix="react-select"
                    />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Contract Start Date</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <input
                    type="date"
                    name="start_date"
                    className="form-control"
                    value={formatDateString(formData.start_date)} // yyyy-mm-dd format
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Contract End Date</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <input
                    type="date"
                    name="end_date"
                    className="form-control"
                    value={formatDateString(formData.end_date) || ''}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              </div>
            </>
          )}

          {type.toLowerCase() === 'part' && (
            <>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Machine Type</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <Select
                    options={machineOptions}
                    value={machineOptions.find(option => option.value === formData.machineType)}
                    onChange={handleDropDownChange('machineType')}
                    classNamePrefix="react-select"
                  />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Machine SubCategory</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <Select
                      options={subCategoryOptions}
                      value={subCategoryOptions.find(option => option.value === formData.machineSubCategory)}
                      onChange={handleDropDownChange('machineSubCategory')}
                      classNamePrefix="react-select"
                    />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Part Name</label>
                  </div>
                </div>
                <div className="col-md-9">
                <div className="form-group">
                  <Select
                    options={filteredParts} // Use the filtered parts here
                    value={filteredParts.find(option => option.value === formData.partName)}
                    onChange={handleDropDownChange('partName')}
                    classNamePrefix="react-select"
                  />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Part Description</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <input
                    type="text"
                    name="partDescription"
                    className="form-control"
                    placeholder="Part Description"
                    value={formData.partDescription || ''}
                    onChange={handleChange}
                  />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Part Price</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <input
                    type="number"
                    name="partCost"
                    className="form-control"
                    placeholder="Part Price"
                    value={formData.partCost || ''}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              </div>
            </>
          )}

          {type.toLowerCase() === 'machine' && (
            <>
               <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Machine Type</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <input
                    type="text"
                    name="machineType"
                    className="form-control"
                    placeholder="Machine Type"
                    value={formData.machineType || ''}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Machine Name</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <input
                    type="text"
                    name="machineName"
                    className="form-control"
                    placeholder="Machine Name"
                    value={formData.machineName || ''}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Machine Sub Category</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <Select
                    options={subCategory} // Ensure this is the correct source of options
                    value={subCategory.find(option => option.value === formData.machineSubCategory)}
                    onChange={handleDropDownChange('machineSubCategory')}
                    classNamePrefix="react-select"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Machine Description</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <input
                    type="text"
                    name="machineDescription"
                    className="form-control"
                    placeholder="Machine Description"
                    value={formData.machineDescription || ''}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Machine Price</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                  <input
                    type="number"
                    name="machinePrice"
                    className="form-control"
                    placeholder="Machine Price"
                    value={formData.machinePrice || ''}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              </div>
            </>
          )}

          <button type="submit" className="btn btn-custom btn-lg">
            {action} {type.charAt(0).toUpperCase() + type.slice(1)}
          </button>
          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

        </form>
      </div>
    </div>
  );
};
