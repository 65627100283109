import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { useSelector } from "react-redux";
import { submitServiceDetails } from "../api/serviceData";
import { getPartsForCustomer } from "../api/parts";
import { useLocation  } from 'react-router-dom';
const initialState = {
    dateTime: "",
    purpose: "",
    parts: "",
    desc: "", 
    ipTds: "",
    opTds:"",
    techName:"",
    techSign:"",
    custSign:""
  };

 
export const VisitComplete = (props) => {
    const [{ desc, ipTds, opTds, techSign }, setState] = useState(initialState);
    const [dateTime, setDateTime] = useState('');
    const [showDesc, setShowDesc] = useState(false);
    const location = useLocation();
    const custSignFromState = useSelector((state) => state.auth.user);
    const custSign =   new URLSearchParams(location.search).get('user') || custSignFromState;
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [errors, setErrors] = useState({}); // State for validation errors
    const [partsOptions, setPartsOptions] = useState([]);
    const phoneFromState = useSelector((state) => state.auth.phone);
    const phone = new URLSearchParams(location.search).get('phone') || phoneFromState;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };    

    const handleSubmit = async(e) =>{
      e.preventDefault();

      if (!validateForm()) {
        return; // Stop submission if validation fails
      }  
    
      try{
      const formData = {
        phone,
        dateTime,
        purpose: selectedOptions.purpose ? selectedOptions.purpose.value : '',
        parts: selectedOptions.parts.map(option => option.value),
        desc,
        ipTds,
        opTds,
        custSign,
        techSign
      };
      const response = await submitServiceDetails(formData);
      setSuccessMessage(`Successfully added Visit Details!`);
    } catch (error) {
      console.error('Error adding data:', error);
      setErrorMessage(error.response ? error.response.data.message : 'Failed to add new service');
    }
  }
    const validateForm = () => {
      const newErrors = {};
      if (selectedOptions.purpose.length === 0) newErrors.purpose = 'Purpose of visit is required.';
      if (selectedOptions.parts.length === 0) newErrors.parts = 'At least one part must be selected.';
      if (!ipTds) newErrors.ipTds = 'Input TDS is required.';
      if (!opTds) newErrors.opTds = 'Output TDS is required.';
      if (!custSign) newErrors.custSign = 'Customer signature is required.';
      if (!techSign) newErrors.techSign = 'Technician signature is required.';
      
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };

    const formatDateTime = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      };
    
      // Update the dateTime state when the component mounts
      useEffect(() => {
        const partsData = async() =>{
          const response = await getPartsForCustomer(phone);
          const options = response.map(part => ({
            value: part.partName, // or part.partName if you want the value to be partName
            label: part.partName
          }));
          options.push({ value: 'Other', label: 'Other' }); // Add "Other" option
          setPartsOptions(options);
        }
        const now = new Date();
        setDateTime(formatDateTime(now));
        partsData();
      }, []); 

    const [selectedOptions, setSelectedOptions] = useState({
      purpose: [],
      parts: []
    });
    // Options for the dropdown
    

    const handleDropDownChange = (field) => (selected) => {
      setSelectedOptions(prevState => {
        const newState = {
          ...prevState,
          [field]: selected
        };
      
      if (field === 'parts') {
        const selectedValues = selected.map(option => option.value);
        setShowDesc(selectedValues.includes('Other'));
      }
      return newState;
     });
    };

    useEffect(() => {
      if (successMessage || errorMessage) {
        const timer = setTimeout(() => {
          setSuccessMessage('');
          setErrorMessage('');
        }, 60000); // 60 seconds
  
        return () => clearTimeout(timer); // Cleanup timer on component unmount or when messages change
      }
    }, [successMessage, errorMessage]);

    return (
    <div id="service">
      <div className="container">
        <div className="col-md-8 section-title">
        <div className="service-container">
          <h2>Enter the Service Details</h2>
          <p>
           Fill the below details to Mark the Service as Complete
          </p>
          </div>
        </div>
        <div id="row">
        <form name="serviceComplete" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                    <label> Today's date and time </label>
                      <input
                        type="text"
                        id="dateTime"
                        name="dateTime"
                        className="form-control"
                        placeholder="Today's date and time"
                        value={dateTime}
                        readOnly
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  
                <div className="col-md-6">
                    <div className="form-group">
                        <div className="label-select">
                        <label>Purpose of Visit</label>
                        </div>           
                        <div>            
                    <Select
                    options={props.data.purpose}
                    value={selectedOptions.purpose}
                    onChange={handleDropDownChange('purpose')}
                    classNamePrefix="form-control" // This class might not be needed if you want to style with CSS directly
                    // Add any additional styles or props if needed
                    />
                    {errors.purpose && <p className="help-block text-danger">{errors.purpose}</p>}
                   
                    </div>   
                    </div>
                  </div>
                </div>

                <div className="col-md-16">
                  <div className="form-group">
                    <div className="label-select">
                        <label> Parts Replaced </label>
                        </div>
                        <div>

                            <Select
                            isMulti
                            options={partsOptions}
                            value={selectedOptions.parts}
                            onChange={handleDropDownChange('parts')}
                            classNamePrefix="form-control"
                            />
                            <p></p>
                            {showDesc && 
                            <input
                                    type="text"
                                    id="desc"
                                    name="desc"
                                    className="form-control"
                                    placeholder="Additional description"
                                    value={desc}
                                    onChange={handleChange}
                                /> }
                            <p></p>

                            {errors.parts && <p className="help-block text-danger">{errors.parts}</p>}
                        </div>
                    </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                    <label> Input TDS </label>

                    <input
                        type="number"
                        id="ipTds"
                        name="ipTds"
                        className="form-control"
                        placeholder="Input TDS"
                        value={ipTds}
                        onChange={handleChange}
                      />
                      {errors.ipTds && <p className="help-block text-danger">{errors.ipTds}</p>}

                    </div>
                  </div> 
                    
                  <div className="col-md-6">
                    <div className="form-group">
                    <label> Output TDS </label>

                    <input
                        type="number"
                        id="opTds"
                        name="opTds"
                        className="form-control"
                        placeholder="Output TDS"
                        value={opTds}
                        onChange={handleChange}

                      />
                      {errors.opTds && <p className="help-block text-danger">{errors.opTds}</p>}

                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Customer Signature </label>
                      <input
                        type="text"
                        id="custSign"
                        name="custSign"
                        className="form-control e-sign"
                        placeholder="Customer's signature"
                        value={custSign}
                        readOnly
                      />
                      </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                        <label> Technician Signature </label>
                          <input
                            type="text"
                            id="techSign"
                            name="techSign"
                            className="form-control e-sign"
                            value={techSign}
                            onChange={handleChange}
                          />
                          {errors.techSign && <p className="help-block text-danger">{errors.techSign}</p>}
                      </div>
                      </div>
                      </div>
                                    
                <button type="submit" className="btn btn-custom btn-lg">
                  Sign & Complete Service
                </button>
                {successMessage && <div className="alert alert-success">{successMessage}</div>}
               {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
              </form>
        </div>
      </div>
    </div>
  );
};
