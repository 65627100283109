import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL ? `${process.env.REACT_APP_BACKEND_URL}/api/service` : 'https://zasheroku-b6ad972d04c9.herokuapp.com/api/service';


export const submitServiceDetails = async (formData) => {
  try {
     console.log(formData);
     const response = await axios.post(`${API_URL}/service-complete`, formData);
        return response.data;  // This will be the response from your backend (e.g., { token: '...'})
    } catch (error) {
      console.error('Error during saving data:', error);
      throw error;
    }
};

export const getServiceDetails = async(phone) => {
    try {
        const response = await axios.get(`${API_URL}/service-details/${phone}` );
        console.log(response.data)
        return response.data;  // This will be the response from your backend (e.g., { token: '...'})
      } catch (error) {
        console.error('Error during fetching service details:', error);
        throw error;
      }
};

export const servicesPerWeek = async() => {
  try{
    const response = await axios.get(`${API_URL}/services-due-this-week`);
    console.log(response);
    return response.data.length;

  } catch(error){
    console.error('Error while fetching services this week', error);
    throw error;
  }
}