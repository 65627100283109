import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { changePassword } from '../api/auth'; // Assume this is the API call for changing the password

export const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const toggleVisibility = (type) => {
    switch(type) {
      case 'current':
        setCurrentPasswordVisible(!currentPasswordVisible);
        break;
      case 'new':
        setNewPasswordVisible(!newPasswordVisible);
        break;
      case 'confirm':
        setConfirmPasswordVisible(!confirmPasswordVisible);
        break;
      default:
        break;
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!currentPassword || !newPassword) {
        setError('Please fill in all fields correctly.');
        setLoading(false);
        return;
    } else if (newPassword !== confirmPassword){
        setError('New password should match with confirm password.');
        setLoading(false);
        return;
    }

    try {
      await changePassword(currentPassword, newPassword);
      localStorage.setItem('needsPasswordChange', 'false'); // Update the local storage
      navigate('/dashboard'); // Redirect to the dashboard or wherever appropriate
    } catch (error) {
      setError('Failed to change password');
    } finally {
      setLoading(false);
    }
  };

  return (
      <div id="login">
          <div className="container">
            <div className="row">
                <div className="col-md-8 offset-md-2">
                <div className="section-title">
                    <h2>Change Password</h2>
                </div>
                <form name="change-password" onSubmit={handleSubmit}>
                    <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                        <input
                            type={currentPasswordVisible ? 'text' : 'password'}
                            id="currentPassword"
                            value={currentPassword}
                            className="form-control"
                            placeholder="Current Password"
                            required
                            onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                        <i
                            className={`fa fa-${currentPasswordVisible ? 'eye-slash' : 'eye'}`}
                            onClick={() => toggleVisibility('current')}
                        ></i>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                        <input
                            type={newPasswordVisible ? 'text' : 'password'}
                            id="newPassword"
                            value={newPassword}
                            className="form-control"
                            placeholder="New Password"
                            required
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <i
                            className={`fa fa-${newPasswordVisible ? 'eye-slash' : 'eye'}`}
                            onClick={() => toggleVisibility('new')}
                        ></i>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                        <input
                            type={confirmPasswordVisible ? 'text' : 'password'}
                            id="confirmPassword"
                            value={confirmPassword}
                            className="form-control"
                            placeholder="Confirm New Password"
                            required
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <i
                            className={`fa fa-${confirmPasswordVisible ? 'eye-slash' : 'eye'}`}
                            onClick={() => toggleVisibility('confirm')}
                        ></i>
                        </div>
                    </div>
                    </div>
                    <p className="help-block text-danger">{error}</p>
                    <button type="submit" className="btn btn-custom btn-lg" disabled={loading}>
                    {loading ? 'Changing Password...' : 'Change Password'}
                    </button>
                </form>
                </div>
            </div>
            </div>
      </div>
  );
};
