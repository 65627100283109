import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL ? `${process.env.REACT_APP_BACKEND_URL}/api/parts` : 'https://zasheroku-b6ad972d04c9.herokuapp.com/api/parts';
export const getPartsForCustomer = async (phone) => {
  console.log(process.env)
  try {
    console.log(phone);
    const response = await axios.get(`${API_URL}/${phone}`);
    return response.data;  // This will be the response from your backend (e.g., { token: '...'})
  } catch (error) {
    console.error('Error during parts:', error);
    throw error;
  }
};

export const getParts = async (phone) => {
    try {
      const response = await axios.get(`${API_URL}`);
      return response.data;  // This will be the response from your backend (e.g., { token: '...'})
    } catch (error) {
      console.error('Error during parts:', error);
      throw error;
    }
  };

export const addPart = async (formData) => {
    try {
        console.log(formData);
      const response = await axios.post(`${API_URL}`, formData);
        return response.data;  // This will be the response from your backend (e.g., { token: '...'})
    } catch (error) {
      console.error('Error during saving data:', error);
      throw error;
    }
  };

  // export const getPart = async (partName, machineType, machineSubCategory) => {
  //   try {
  //     const response = await axios.get(`${API_URL}/getPart`, {
  //       params: { partName, machineType, machineSubCategory }
  //     });
  //     return response.data;  // This will return { id: '...' }
  //   } catch (error) {
  //     console.error('Error fetching part:', error);
  //     throw error;
  //   }
  // };

  export const updatePart = async (formData) => {
    try {
      // const part = await getPart(partName, machineType, machineSubCategory);

        const response = await axios.post(`${API_URL}/updatePart`, formData);
        return response.data;  // This will be the response from your backend (e.g., { token: '...'})
    } catch (error) {
      console.error('Error during saving data:', error);
      throw error;
    }
  };