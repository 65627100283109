// src/api/auth.js

import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL ? `${process.env.REACT_APP_BACKEND_URL}/api/auth` : 'https://zasheroku-b6ad972d04c9.herokuapp.com/api/auth';

export const login = async (username, password) => {
  try {
    const response = await axios.post(`${API_URL}/login`, {
      username,
      password,
    });

    console.log(response.data);
    return response.data;  // This will be the response from your backend (e.g., { token: '...'})
  } catch (error) {
    console.error('Error during login:', error);
    throw error;
  }
};

export const changePassword = async (currentPassword, newPassword) => {
  const token = localStorage.getItem('authToken'); // Get token from local storage

  if (!token) {
    throw new Error('No authentication token found');
  }

  try {
    const response = await axios.post(`${API_URL}/change-password`, {
      currentPassword,
      newPassword,
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    return response.data; // Return response data if needed
  } catch (error) {
    if (error.response) {
      // Handle HTTP error response
      throw new Error(error.response.data.message || 'Failed to change password');
    } else {
      // Handle other types of errors
      throw new Error('An unexpected error occurred');
    }
  }
};