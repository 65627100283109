// store.js
import { createStore } from 'redux';
import rootReducer from './redux/index'; // Adjust the path as needed

const initialState = {
    isAuthenticated: !!localStorage.getItem('authToken'), // Check if token exists
    user: localStorage.getItem('authToken') ? localStorage.getItem('name') : null,
    phone: localStorage.getItem('authToken') ? localStorage.getItem('phone') : null,
    isAdmin:  localStorage.getItem('authToken') ? localStorage.getItem('isAdmin') == 'true' ? true: false : null,
  };
  
  
const store = createStore(
  rootReducer,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() // Optional: Redux DevTools extension
);

export default store;
