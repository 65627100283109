import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getCustomerData } from "../api/customerData";
import { getServiceDetails } from "../api/serviceData";
import { UserCard } from "./userCard";
import { ServiceCard } from "./serviceCard";
import { useNavigate, useLocation  } from 'react-router-dom';

export const Dashboard = (props) => {
  const [serviceDetails, setServiceDetails] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [loadingService, setLoadingService] = useState(false);
  const [loadingIssue, setLoadingIssue] = useState(false);
  const [loadingSchedule, setLoadingSchedule] = useState(false);
  const [error, setError] = useState(null);
  const phoneFromState = useSelector((state) => state.auth.phone); // Get phone from Redux state
  const customerFromState = useSelector((state) => state.auth.user); // Get phone from Redux state
  const location = useLocation();
  
  // Get phone from URL if it's not available in Redux state
  const phone = new URLSearchParams(location.search).get('phone') || phoneFromState;
  const userName = new URLSearchParams(location.search).get('user') || customerFromState;

  const navigate = useNavigate();


  const handleServiceComplete = () => {
    setLoadingService(true);
    navigate(`/visit_complete?phone=${phone}&user=${userName}`);
  };

  const handleClick = (type) => async () => {
    if (type === 'issue') {
      setLoadingIssue(true);
    } else if (type === 'schedule') {
      setLoadingSchedule(true);
    }
    try {
      // Simulate an async operation
      await new Promise((resolve) => setTimeout(resolve, 3000));
    } catch (error) {
      console.error('Operation failed:', error);
    } finally {
      if (type === 'issue') {
        setLoadingIssue(false);
      } else if (type === 'schedule') {
        setLoadingSchedule(false);
      }
    }
  };

  useEffect(() => {
    if (!phone) return; // Early return if phone is not available
    // Fetch customer data
    const fetchData = async () => {
      setLoadingService(true);
      try {
        const response = await getCustomerData(phone);
        setCustomer(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoadingService(false);
      }
    };

    // Fetch service details
    const fetchServiceDetails = async () => {
      setLoadingService(true);
      try {
        const response = await getServiceDetails(phone);
        setServiceDetails(response);
        console.log(response); // Fixed typo here
      } catch (error) {
        setError(error);
      } finally {
        setLoadingService(false);
      }
    };

    // Only fetch data when phone changes
    fetchData();
    fetchServiceDetails();
  }, [phone]); // Ensure `phone` is included in dependencies

  return (
    <div id="dashboard">
      <div className="container">
        <div className="row">
          <div className="col-md-8 intro-text">
            <div className>
            <button
                type="button"
                className="btn btn-custom btn-lg"
                disabled={loadingService}
                onClick={handleServiceComplete}
              >
                {loadingService ? 'Loading' : 'Enter Visit Summary'}
              </button>

              <button
                type="button"
                className="btn btn-custom btn-lg"
                disabled={loadingSchedule}
                onClick={handleClick('schedule')}
              >
                {loadingSchedule ? 'Coming soon!' : 'Schedule a Service'}
              </button>

            
              <button
                type="button"
                className="btn btn-custom btn-lg"
                disabled={loadingIssue}
                onClick={handleClick('issue')}
              >
                {loadingIssue ? 'Coming soon!' : 'Report An Issue'}
              </button>
            </div>
            <UserCard user={customer} title="Customer Details" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <ServiceCard service={serviceDetails} title="Service History" data={props.data} />
          </div>
        </div>
      </div>
    </div>
  );
};
