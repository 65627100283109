import React, { useState, useEffect } from 'react';
import Navigation from './navigation';
import { Header } from './header';
import { Products } from './products';
import { About } from './about';
import { Services } from './services';
import { Testimonials } from './testimonials';
import { Contact } from './contact';
import JsonData from '../data/data.json';

export const Home = () => {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);


  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Products data={landingPageData.Products} />
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <Testimonials data={landingPageData.Testimonials} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default Home;
