
// reducers/authReducer.js
const initialState = {
    isAuthenticated: !!localStorage.getItem('authToken'), // Check if token exists
    user: localStorage.getItem('authToken') ? localStorage.getItem('name') : null,
    phone: localStorage.getItem('authToken') ? localStorage.getItem('phone') : null,
    isAdmin: localStorage.getItem('authToken') ? localStorage.getItem('isAdmin') == 'true' ? true: false : null,
  };


  const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.name,
        phone: action.payload.phone,
        isAdmin: action.payload.isAdmin
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        isAdmin: null
      };
    default:
      return state;
  }
};

export default authReducer;
