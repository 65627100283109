import React from 'react';
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { Modal } from './Modal'; // Adjust the import as needed
import { deleteCustomer } from '../api/customerData';
const GlobalFilter = ({ globalFilter, setGlobalFilter }) => (
  <input
    className="search-input"
    value={globalFilter || ''}
    onChange={(e) => setGlobalFilter(e.target.value || undefined)}
    placeholder="Search..."
  />
);


const ReusableTable = ({
  columns,
  data,
  filterInput,
  setFilterInput,
  subCategory,
  tableType,
  cities,
  parts
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { globalFilter, pageIndex, pageSize },
    setGlobalFilter,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { globalFilter: filterInput },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    setGlobalFilter(filterInput || undefined);
  }, [filterInput, setGlobalFilter]);

  const handleRowClick = (phone, username) => {
    navigate(`/dashboard?phone=${phone}&user=${username}`);
  };

  const handleEditClick = (rowData) => {
    setSelectedRowData(rowData);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedRowData(null); // Clear selected data on close
  };

  const handleDeleteClick = async (phone) => {
    // Handle saving logic, e.g., updating state or making an API call
    const confirmDelete = window.confirm("Are you sure you want to delete this customer?");
    if (!confirmDelete) return;
  
    try {
      // Make an API call to delete the item
      const response = await deleteCustomer(phone);
  
      alert("Customer deleted successfully!");
    } catch (error) {
      console.error("Error deleting customer:", error);
      // Optionally show an error message
      alert("Failed to delete the Customer. Please try again.");
    }
  };


  const editIcon = <span style={{ cursor: 'pointer' }}>✏️</span>; // Use an actual icon or image as needed

  return (
    <div className="user-card admin-data">
       <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        type={tableType}
        subCategory={subCategory}
        initialData={selectedRowData}
        cities={cities}
        parts={parts}
      />
      <div className="user-card__header">
        <div className="user-card__title">{tableType} Data</div>
        <div className="user-card__content">
          <div className="table-container">
            <GlobalFilter globalFilter={filterInput} setGlobalFilter={setFilterInput} />
            <table {...getTableProps()} className="user-table">
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' 🔽'
                              : ' 🔼'
                            : ''}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}
                      onClick={() => tableType === 'Customer' && handleRowClick(row.cells[1].value, row.cells[0].value)}
                      style={{ cursor: tableType === 'Customer' ? 'pointer' : 'default' }}>
                      {row.cells.map(cell => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                      <td onClick={(e) => {
                        e.stopPropagation(); // Prevent row click
                        handleEditClick(row.original); // Pass the entire row data
                      }}>
                      {tableType === 'Customer' && (
                      <div className="tooltip-container">
                        {editIcon}
                        <span className="tooltip">Edit</span>
                      </div>
                      )} 
                      </td>
                      <td onClick={(e) => {
                        e.stopPropagation(); // Prevent row click
                        handleDeleteClick(row.original.phone); // Pass the ID or relevant data for deletion
                      }}>
                      {tableType === 'Customer' &&  (
                       <div className="tooltip-container">
                        <i className="fa fa-trash" style={{ cursor: 'pointer', color: 'red' }}></i>
                        <span className="tooltip">Delete</span>
                      </div>
                    )} 
                    </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="pagination-controls">
              <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'< Previous'}
              </button>
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                {'Next >'}
              </button>
              <span>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
              </span>
              <span>
                | Go to page:{' '}
                <input
                  type="number"
                  min={1}
                  max={pageOptions.length}
                  defaultValue={pageIndex + 1}
                  onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                    gotoPage(page);
                  }}
                />
              </span>
              <select
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30].map(size => (
                  <option key={size} value={size}>
                    Show {size}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReusableTable;