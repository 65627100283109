// reducers/index.js
import { combineReducers } from 'redux';
import authReducer from './authReducer'; // Adjust the path as needed

const rootReducer = combineReducers({
  auth: authReducer,
  // Add other reducers here
});

export default rootReducer;
