import React from "react";
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";

const Navigation = ({  isAuthenticated, isAdmin, onLogout }) => {
  const navigate = useNavigate();
  // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  // const isAdmin = useSelector((state) => state.auth.isAdmin);

  console.log(isAuthenticated);
  const handleLogout = (event) => {
    event.preventDefault(); // Prevent the default anchor behavior
    localStorage.removeItem('authToken'); // Clear token
    onLogout();
    navigate('/login'); // Redirect to login page after logout
  };

  const handleNavigation = (hash) => (event) => {
    event.preventDefault(); // Prevent default anchor behavior
    navigate(`/home${hash}`); // Navigate to the path with hash
    setTimeout(() => {
      // Scroll to the section after navigation
      const section = document.querySelector(hash);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100); // Small delay to ensure the page has loaded
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container-navbar">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand page-scroll" href="/home#page-top">
            Zeel Aqua Solutions
          </a>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="/home#products" className="page-scroll" onClick={handleNavigation('#products')}>
                Products
              </a>
            </li>
            <li>
              <a href="/home#about" className="page-scroll" onClick={handleNavigation('#about')}>
                About
              </a>
            </li>
            <li>
              <a href="/home#services" className="page-scroll" onClick={handleNavigation('#services')}>
                Services
              </a>
            </li>
            <li>
              <a href="/home#testimonials" className="page-scroll" onClick={handleNavigation('#testimonials')}>
                Testimonials
              </a>
            </li>
            <li>
              <a href="/home#contact" className="page-scroll" onClick={handleNavigation('#contact')}>
                Contact
              </a>
            </li>
            {isAuthenticated ? (
              <>
                {isAdmin === true ? (
                  <li>
                    <a href="/admin" className="page-scroll">
                      Admin
                    </a>
                  </li>
                ) : (
                  <li>
                    <a href="/dashboard" className="page-scroll">
                      Dashboard
                    </a>
                  </li>
                )}
                <li>
                  <a href="/logout" onClick={handleLogout} className="page-scroll">
                    Logout
                  </a>
                </li>
              </>
            ) : (
              <li>
                <a href="/login" className="page-scroll">
                  Login
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isAdmin: state.auth.isAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch({ type: 'LOGOUT' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
