import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL ? `${process.env.REACT_APP_BACKEND_URL}/api/machine` : 'https://zasheroku-b6ad972d04c9.herokuapp.com/api/machine';
export const getMachines = async () => {
  try {
    const response = await axios.get(`${API_URL}/distinct-machine-type`);
    return response.data;  // This will be the response from your backend (e.g., { token: '...'})
  } catch (error) {
    console.error('Error during fetching machine:', error);
    throw error;
  }
};

export const getAllMachines = async () => {
    try {
      const response = await axios.get(`${API_URL}`);
      return response.data;  // This will be the response from your backend (e.g., { token: '...'})
    } catch (error) {
      console.error('Error during fetching machine:', error);
      throw error;
    }
  };
  

export const addMachine = async (formData) => {
    try {
      const response = await axios.post(`${API_URL}`, formData);
        return response.data;  // This will be the response from your backend (e.g., { token: '...'})
    } catch (error) {
      console.error('Error during saving data:', error);
      throw error;
    }
  };

  export const updateMachine = async (formData) => {
    try {
      const response = await axios.post(`${API_URL}/updateMachine`, formData);
        return response.data;  // This will be the response from your backend (e.g., { token: '...'})
    } catch (error) {
      console.error('Error during saving data:', error);
      throw error;
    }
  };