// src/components/UserCard.js
import React from 'react';
import { getFormattedDate } from '../api/utils';
export const UserCard = ({ user, title }) => {
  if (!user) return <p>No user data available</p>;


  const labelMap = {
    start_date: 'CONTRACT START DATE',
    end_date: 'CONTRACT RENEWAL DATE'
  };

  const iconMap = {
    name: 'fa-user',
    phone: 'fa-phone',
    address: 'fa-home',
    city: 'fa-building',
    start_date: 'fa-calendar',
    end_date: 'fa-calendar'
  };
  

  const data = Object.entries(user).map(([label, value]) => ({
    label: labelMap[label]  || label.replace(/_/g, ' ').toUpperCase(), // Convert snake_case to readable format
    value: (label === 'start_date' || label === 'end_date') ? getFormattedDate(value):value,
    icon: iconMap[label] || 'fa-info-circle' // Default icon
  }));

  return (
    
    <div className="user-card">
      <div className="user-card__header">
        <div className="user-card__title">{title}</div>
      <div className="user-card__content">
      <div className="table-container">
      <table className="user-table">
        <tbody>
          {data.map((item, index) => (
            <React.Fragment key={index}>
            {item.label === 'ADDRESS' ? (
              <>
                <tr>
                  <td className="label" rowSpan={2}>
                    <i className={`fa ${item.icon}`}></i> {item.label}
                  </td>
                  <td className="value">{item.value}</td>
                </tr>
                {/* Add an additional row if the address is long */}
                <tr>
                  <td className="value" style={{ padding: '0', border: 'none' }}>
                    {/* This cell is empty to ensure address spans multiple rows */}
                  </td>
                </tr>
              </>
            ) : (
              <tr key={index}>
                <td className="label">
                  <i className={`fa {item.label === 'ADDRESS' ? ${item.icon}`}></i> {item.label}
                </td>
                <td className="value">{item.value}</td>
              </tr>
            )}
          </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
      </div>
    </div>
    </div>



  );
};

